import request from '@/utils/request'




/**
 * 用户信息表格列表
 * @param params
 * @returns {*}
 */
export function http_user_table_list(data) {
    return request({
        url: '/user/table/list',
        method: 'post',
        data
    })
}



/**
 * 用户信息详情
 * @param params
 * @returns {*}
 */
export function http_user_table_info(data) {
    return request({
        url: '/user/table/info',
        method: 'post',
        data
    })
}