//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {http_logout_system} from "@/api/login"
import {http_user_table_info} from "@/api/user"

export default {
  name: "Home",
  data() {
    return {
      activeIndex: '1',
      menuList: [
        {title: "聊天会话", index: '1', url: "/home/session"},
        {title: "数据分析", index: '2', url: "/home/statistics"},
        {title: "客户管理", index: '3', url: "/home/customer"},
        {title: "账号管理", index: '4', url: "/home/account"},
        {title: "系统设置", index: '5', url: "/home/setting"},
      ],
      user_name: "",
      ws: null,
      client_exists_list: false
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.connect_ws_service();
      this.loadLoginUserInfo();
    })
  },
  methods: {
    handleSelect(data) {
      console.log(data);
    },
    clickUrl(row) {
      this.activeIndex = row.index;
      this.$router.push(row.url);
    },


    connect_ws_service() {
      let wsurl = "wss://ws.yskcrm.com"
      this.ws = new WebSocket(wsurl);
      this.ws.onopen = this.onWsOpen;
      this.ws.onmessage = this.onWsMessage;
      this.ws.onclose = this.onWsClose;
    },
    onWsOpen() {
      console.log("连接成功");
      if (this.ws != null) {

        //注册账号:authorization register
        let authorization = localStorage.getItem("authorization");
        this.ws.send(JSON.stringify({action: 'register', data: authorization}));
        //循环心跳
        this.onHeartMessage();
      }
    },
    onWsMessage(evt) {
      console.log("接收了信息");


      let message = JSON.parse(evt.data);
      console.log(message);
      let ghid = message.ghid;
      let open_id = message.open_id;
      let _this = this;

      if (ghid == undefined) {
        console.log("该消息不是对话的message");
        return;
      }

      if (_this.$store.state.session_user_item == null) {
        console.log("被选中的用户数据异常");
        this.$store.commit("set_session_chat_new_message_customer", message);
        return;
      }


      if (message.opercode == 1000) {
        let icon = message.avatar;
        let title = message.account_nickname;
        let content = message.chat;
        if (message.message_type == "image") {
          content = "【图片】";
        }
        this.notify(icon, title, content);
      }


      if (_this.$store.state.session_merchant_item.open_id == ghid) {

        //就是当前账号来了信息，
        console.log("是当前选择的账号发来了消息......");
        this.client_exists_list = false;

        //如果消息刚好是当前用户的消息。
        if (_this.$store.state.session_user_item.open_id == open_id) {
          _this.$store.state.session_chat_content.push(message);
          _this.$store.commit("set_session_chat_new_message", new Date());
          this.client_exists_list = true;
        }

        let user_list = _this.$store.state.session_user_list;
        for (const user_key in user_list) {


          // console.log("循环内容：" + user_list[user_key].nickname + "@" + user_list[user_key].open_id);


          if (user_list[user_key].open_id == open_id) {
            this.client_exists_list = true;
            let info_user = JSON.parse(JSON.stringify(user_list[user_key]));

            if (_this.$store.state.session_user_item.open_id == open_id) {
              info_user.unread = 0;
            } else {
              info_user.unread++;
            }
            info_user.last_message = message.chat;
            info_user.last_timestamp_string = message.create_timestamp;
            _this.$set(user_list, user_key, info_user);
            _this.$store.commit("set_session_user_list", user_list);
            break;
          }

          if (!this.client_exists_list) {
            //不存在，那么这是一个新的粉丝，需要去请求接口，获取这个粉丝的信息，并且添加到列表中去。
            // let user_info =
          }

        }

      } else {

        if (message.opercode != 1000) {
          return ;
        }

        let merchant_list = _this.$store.state.session_merchant_list;
        for (const merchant_key in merchant_list) {
          let merchant_data = merchant_list[merchant_key];
          if (message.ghid == merchant_data.open_id) {
            let info_merchant = JSON.parse(JSON.stringify(merchant_list[merchant_key]));
            info_merchant.unread++;
            _this.$set(merchant_list, merchant_key, info_merchant);
            _this.$store.commit("set_session_merchant_list", merchant_list);
            break;
          }
        }

      }


    },
    onWsClose() {
      console.log("掉线了");
    },
    onHeartMessage() {
      let _this = this;
      setInterval(function () {
        if (_this.ws.readyState != _this.ws.OPEN) {
          _this.connect_ws_service();
        }
        _this.ws.send(JSON.stringify({action: 'heart', data: "message"}));
      }, 10000);
    },
    loadLoginUserInfo() {
      let userinfo = JSON.parse(localStorage.getItem("laravel_userinfo"));
      this.user_name = userinfo.name;
    },
    handleCommand(data) {
      if (data == "logout") {

        this.$confirm('此操作将退出系统, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //请求退出系统。
          http_logout_system({}).then(res => {
            console.log(res);
            location.href = '/';
          })
        }).catch(() => {
        });

      }
    },
    notify(icon, title, content) {
      console.log("桌面通知")
      let options = {
        dir: "auto", // 文字方向
        body: content, // 通知主体
        requireInteraction: true, // 不自动关闭通知
        icon: icon //通知图标
      };

      if (window.Notification) {
        if (Notification.permission === 'granted') {
          let notification = new Notification(title, options); // 显示通知
          console.log(notification);
        } else if (Notification.permission === 'default') {
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              console.log('用户同意授权');
              let notification = new Notification(title, options); // 显示通知
              console.log(notification);
            } else if (permission === 'default') {
              console.warn('用户关闭授权 未刷新页面之前 可以再次请求授权');
            } else {
              this.$message.error("用户拒绝授权 不能显示通知");
            }
          });
        }
        const audio = new Audio("/mp3/qq.mp3");
        audio.play();
      } else {
        this.$message.error("用户拒绝显示通知");
      }
      // console.log(window.webkitNotifications);
      // if (window.webkitNotifications) {
      //   if (window.webkitNotifications.checkPermission() > 0) {
      //     this.RequestPermission(this.notify(icon, title, content));
      //   } else {
      //     //图标，标题，内容
      //     let notification = webkitNotifications.createNotification(icon, title, content);
      //     notification.show();
      //   }
      // }else{
      //   console.log("不支持桌面提示");
      // }
    },
    RequestPermission(callback) {
      window.webkitNotifications.requestPermission(callback);
    }


  }
}
