import request from '@/utils/request'


/**
 * 登录系统
 * @param data
 * @returns {*}
 */
export function http_login_system(data) {
    return request({
        url: '/auth/login',
        method: 'post',
        data
    })
}


/**
 * 注销系统
 * @param data
 * @returns {*}
 */
export function http_logout_system(data) {
    return request({
        url: '/auth/logout',
        method: 'post',
        data
    })
}

